<template>
   <div class="row justify-content-center m-1 px-1">
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-journal-richtext" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Fetch Missing Amazon Catalog Items
              </h4>
              <p class="card-text mb-0">
                Catalog Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To fetch the missing Amazon catalog items by store ID, please enter the <b class="text-warning"> STORE ID </b> and click the button below.
                </p>
                <p class="my-0">
                  If you enter 0 as the store ID, it will update Amazon catalog items of all stores.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-sm-6">
                <label for="storeID">Store ID</label>
                <dx-util-number-box id="storeID" v-model="storeID" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Store ID is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-12 col-sm-6 pl-sm-1">
                <label>&nbsp;</label>
                <dx-util-button text="Fetch Missing Amazon Catalog Items" type="default" styling-mode="contained" :element-attr="btnElementAttr" @click="getMissingAmazonCatalogItems" />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-journal-richtext" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Update Product With Amazon Data
              </h4>
              <p class="card-text mb-0">
                Catalog Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To update product with Amazon data, please enter the <b class="text-warning">SKIP</b> value and click the button below.
                </p>
                <p class="my-0">
                  Skip value x 30.0000 record will be skipped.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-sm-6">
                <label for="skip">Skip</label>
                <dx-util-number-box id="skip" v-model="skip" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Skip value is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-12 col-sm-6 pl-sm-1">
                <label>&nbsp;</label>
                <dx-util-button text="Update Product with Amazon Data" type="default" styling-mode="contained" :element-attr="btnElementAttr" @click="updateProducts" />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-journal-richtext" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Update Product With Missing Images
              </h4>
              <p class="card-text mb-0">
                Catalog Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="m-0">
                To update product <b class="text-warning"> missing images </b>, please click the button below.
              </p>
              </div>
            </div>
            <div class="form-row align-items-center">
              <div class="ml-none col-12 col-xl-6">
                <label>&nbsp;</label>
                <dx-util-button
                    text="Update Product With Missing Images"
                    type="default"
                    styling-mode="contained"
                    :element-attr="btnElementAttr"
                    @click="updateProductsMissingImages"
                  />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-journal-richtext" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Get Catalog Item By ASIN
              </h4>
              <p class="card-text mb-0">
                Catalog Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To get catalog item by ASIN, please enter the <b class="text-warning"> ASIN </b> and click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-sm-6">
                <label for="skip">ASIN</label>
                <dx-util-text-box id="asin" v-model="asin" styling-mode="filled">
                  <dx-util-validator>
                        <dx-util-required-rule message="ASIN is required" />
                        <dx-util-custom-rule :validation-callback="checkAsin" message="Invalid ASIN" />
                      </dx-util-validator>
                </dx-util-text-box>
              </div>
              <div class="col-12 col-sm-6 pl-sm-1">
                <label>&nbsp;</label>
                <dx-util-button text="Get Catalog Item By ASIN" type="default" styling-mode="contained" :element-attr="btnElementAttr" @click="openCatalogItem" />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <catalog-item :asin="selectedAsin" @on-emit-close="selectedAsin=null" />
  </div>
</template>

<script>
import maintenanceService from '@/http/requests/common/maintenanceService'
import { Notify } from '@/@robustshell/utils'
import { isValidAsin } from '../../ship/validationItem.js'

export default {
  components: {
    'catalog-item': () => import('./CatalogItem.vue'),
  },
  data() {
    return {
      storeID: null,
      accountNo: null,
      skip: null,
      asin: null,
      btnElementAttr: {
        class: 'btn-block',
      },
      isVisible: false,
      selectedAsin: null,
    }
  },
  methods: {
    checkAsin() {
      return isValidAsin(this.asin)
    },
    getMissingAmazonCatalogItems(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        maintenanceService.fetchMissingAmazonCatalogItems(this.storeID).then(() => {
          Notify.success('Get Missing Amazon Catalog Items Job is created successfully')
        }).catch(error => {
          Notify.error(`Job creation failed: ${error}`)
        })
      }
    },
    updateProducts(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        maintenanceService.updateProductsFromAmazonData(this.skip).then(() => {
          Notify.success('Get Missing Amazon Catalog Items Job is created successfully')
        }).catch(error => {
          Notify.error(`Job creation failed: ${error}`)
        })
      }
    },
    updateProductsMissingImages() {
      maintenanceService.updateProductsWithMissingImages().then(() => {
        Notify.success('Get Missing Amazon Catalog Items Job is created successfully')
      }).catch(error => {
        Notify.error(`Job creation failed: ${error}`)
      })
    },
    openCatalogItem(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        this.selectedAsin = this.asin
        this.isVisible = true
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.btn-min-width {
  min-width: 250px;
}
</style>
